.button-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 20px;
}

.grid-button {
  position: relative;
  border-radius: 50px;
  background: black;
  border: 2px solid black;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
}

.grid-button img {
  width: 100%;
  height: auto;
  border-radius: 50px;
}

.grid-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.3s ease;
  border-radius: 50px;
}

.grid-button:hover::before {
  background-color: rgba(255, 255, 255, 0.2);
}

.grid-button.selected::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./Assets/Selected.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.select-schools-checkout-button {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  background-color: #0055ff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 1000;
}

.select-schools-checkout-button:hover {
  background-color: #0056b3;
}

.select-schools-checkout-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}