.password-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(45deg, #5456fa, #a855f7);
  z-index: 9999;
  overflow: hidden;
}

/* Cursor light effect */
.password-container::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at var(--x, 50%) var(--y, 50%),
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.1) 20%,
    rgba(255, 255, 255, 0) 60%
  );
  transform: translate(calc(var(--x, 0) * 1px - 50%), 
                      calc(var(--y, 0) * 1px - 50%));
  pointer-events: none;
  transition: transform 0.1s ease;
}

/* Floating circles */
.password-container::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(circle at 80% 20%, rgba(255,255,255,0.03) 0%, transparent 40%),
    radial-gradient(circle at 20% 80%, rgba(255,255,255,0.03) 0%, transparent 40%),
    radial-gradient(circle at 50% 50%, rgba(255,255,255,0.03) 0%, transparent 40%),
    radial-gradient(circle at 80% 80%, rgba(255,255,255,0.03) 0%, transparent 40%),
    radial-gradient(circle at 20% 20%, rgba(255,255,255,0.03) 0%, transparent 40%);
  animation: float 20s ease-in-out infinite;
}

.password-box {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  z-index: 2;
  border: 1px solid rgba(255, 255, 255, 0.2);
  min-width: 300px;
}

.password-box h1 {
  color: white;
  margin-bottom: 30px;
  font-size: 2.5em;
  font-weight: 300;
}

.password-box input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: none;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  font-size: 16px;
  transition: all 0.3s ease;
}

.password-box input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.password-box input:focus {
  outline: none;
  background: rgba(255, 255, 255, 0.3);
}

.password-box input.error {
  animation: shake 0.5s linear;
}

.password-box button {
  background: white;
  color: #6366f1;
  border: none;
  padding: 12px 30px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.password-box button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.error-message {
  color: #ff6b6b;
  margin-top: 10px;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-20px) scale(1.1);
  }
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-10px); }
  75% { transform: translateX(10px); }
}
