/* generalBanner.css */

/* Banner Container */
.banner {
  width: 100%;
  height: 60px;
  background-color: white;
  border-bottom: 1px solid lightgrey;
  display: flex;
  align-items: center;
  padding-left: 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

/* SBC Logo */
.banner-logo {
  height: 60px;
}

.sidebar-header .new-chat-icon {
  height: 24px; /* Adjust icon size as needed */
  width: 24px;
  cursor: pointer;
}

/* Menu Button */
.menu-button {
  height: 25px;
  width: 25px;
  margin-right: 20px;
  margin-left: 30px;
  cursor: pointer;
}

.settings-button {
  height: 35px; /* Adjust size as needed */
  width: 35px;  /* Adjust size as needed */
  cursor: pointer;
  position: absolute;
  right: 80px; /* Add some padding from right edge */
  top: 50%;
  transform: translateY(-50%);
}

/* Sidebar Styling */
.sidebar {
  position: fixed;
  bottom: 0;
  left: -250px;
  width: 250px;
  height: 90%;
  background-color: #f4f4f4;
  box-shadow: 2px 0 5px rgba(0,0,0,0.3);
  transition: left 0.3s ease;
  padding: 20px;
  box-sizing: border-box;
  z-index: 999;
}

.sidebar.open {
  left: 0;
}

/* Sidebar Header */
.sidebar-header {
  margin-bottom: 20px;
}

/* Sidebar Header Title */
.sidebar-header h2 {
  font-size: 20px;
  margin: 0;
  text-align: left;
}

/* Chat List Styling */
.sidebar .chat-list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  max-height: 99%;
  overflow: visible;
}

/* Each Chat Item */
.sidebar .chat-list li {
  margin-bottom: 10px;
}

/* Chat Link Styling */
.sidebar .chat-link {
  background-color: #e0e0e0;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  text-align: left;
  width: 90%;
  transition: background-color 0.2s, color 0.2s;
}

/* Hover Effect for Chat Links */
.sidebar .chat-link:hover {
  background-color: #d5d5d5;
  color: #007BFF;
}

/* Main Content Shift When Sidebar is Open */
body.sidebar-open .main-content {
  transform: translateX(225px);
  transition: transform 0.3s ease;
}

.main-content {
  transition: transform 0.3s ease;
  margin-top: 60px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .sidebar {
    width: 80%;
    left: -80%;
  }

  body.sidebar-open .main-content {
    transform: translateX(80%);
  }
}

/* Adjust chat-link to be a flex container */
.chat-link {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.chat-link:hover {
  background-color: #d5d5d5;
  color: #007BFF;
}

/* Style for the chat title */
.chat-title {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Adjust options button */
.chat-options-button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.chat-options-button img {
  height: 10px; /* Adjust icon size as needed */
}

.chat-options-button:hover {
  background-color: transparent; /* Prevents hover background color */
}

/* Position the options menu */
.chat-list-item {
  position: relative; /* For positioning the options menu */
}

/* Options Menu */
.chat-options-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: gray;
  border: 1px solid black;
  z-index: 1;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
}

.chat-options-menu button {
  display: block;
  width: 100%;
  padding: 5px 10px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
}

.chat-options-menu button:hover {
  background-color: #a3a3a392;
}