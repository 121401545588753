.main-container {
  width: 100%;
  transition: margin-left 0.3s ease, width 0.3s ease;
}

/* Adjust layout when sidebar is open */
body.sidebar-open .main-container {
  margin-left: 245px;
  width: calc(100% - 245px);
}

.questions-grid {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  gap: 15px; /* Increased gap for better separation between questions */
  padding: 20px;
}

.question-header {
  font-size: 24px; /* Larger font size for the header */
  font-weight: bold; /* Make it bold */
  color: #333; /* Darker color for better contrast */
  margin-bottom: 10px; /* Add spacing below the header */
  text-align: center; /* Center the header */
}

.logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px; /* Add space below the logo */
}

.school-logo {
  max-width: 100px; /* Set a max width for the logo */
  margin-right: 15px; /* Add spacing between the logo and the header */
}

.question-button {
  background: white;
  border: 1px solid lightgrey;
  border-radius: 8px; /* Rounded corners for a smoother look */
  padding: 15px 20px; /* Adjusted padding for more breathing space */
  cursor: pointer;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px; /* Slightly larger font for readability */
  color: #333; /* Darker text color for better contrast */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for a raised effect */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition effect */
}

.question-button:hover {
  background-color: #f9f9f9; /* Light background on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Increased shadow on hover */
}

.question-button:focus {
  outline: none;
  border-color: #007bff; /* Blue border on focus */
}
