.chat-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  transition: margin-left 0.3s ease, width 0.3s ease;
}

body.sidebar-open .chat-page {
  margin-left: 245px;
  width: calc(100% - 245px);
}

.logo-and-question {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  position: relative;
}

.school-logo {
  max-width: 150px;
  margin-right: 20px;
}

.question-text {
  font-size: 20px;
  margin-bottom: 20px;
}

.chat-window::-webkit-scrollbar {
  display: none;
}

.chat-window {
  flex: 1;
  align-self: center;
  -ms-overflow-style: none;
  scrollbar-width: none;  
  padding: 20px;
  background-color: white;
  width: 80%;
  margin-top: 80px;
}

.chat-message {
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  max-width: 60%;
  text-align: left; /* Ensures text is left-aligned */
  word-wrap: break-word;
}

.chat-message.user {
  background-color: #e0e0e0;
  align-self: flex-end;
  border-radius: 10px;
  word-wrap: break-word;
  max-width: 60%;
  min-width: 50px;
  text-align: right; /* Ensures user message text is right-aligned */
  margin-left: auto; /* Pushes the user's message to the right side */
  border: 1px solid #ccc;
}

.chat-message.gpt {
  background-color: transparent; /* No background */
  align-self: flex-start;
  max-width: 70%;
  word-wrap: break-word;
  font-family: Arial, sans-serif; /* Change to Arial font */
  white-space: pre-wrap; /* Preserve line breaks */
  color: #333; /* Darker text color for better readability */
  padding: 5px; /* Optional padding */
  text-align: left; /* Ensures GPT message text is left-aligned */
}

.chat-message.gpt strong {
  font-weight: bold; /* Bold for text enclosed in ** */
}

.chat-message.gpt em {
  font-style: italic; /* Style for italicized text */
}

.chat-message.gpt code {
  font-family: 'Courier New', Courier, monospace; /* Code block style */
  background-color: #f4f4f4;
  padding: 2px 4px;
  border-radius: 3px;
}

.chat-input-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: #e7e7e7; 
  padding: 5px;
  border-radius: 20px;
  width: 50%;
  margin: 0 auto;
  position: sticky;
  bottom: 10px;
}

.user-input {
  flex: 1;
  padding: 5px 10px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1;
  border: none;
  background-color: #ffffff;
  color: #000000;
  border-radius: 20px;
  outline: none;
  margin-right: 10px;
  resize: none;
  overflow: hidden;
  min-height: 10px;
  max-height: 200px;
}

.user-input::placeholder {
  color: #000000; /* Match the placeholder text color with the typed text */
  font-family: Arial, sans-serif; /* Ensure the font matches */
  font-size: 16px; /* Ensure the font size matches */
  line-height: 1; /* Same line-height as text */
}

.generate-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease;
  outline: none; /* Remove the default focus outline */
  margin-bottom: 5px;
}

.generate-button:hover,
.generate-button:focus {
  background-color: transparent; /* Ensure background stays transparent on focus */
}

.generate-button img {
  width: 30px;
  height: auto;
}

.paperclip {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease;
  outline: none;
  margin-right: 10px; /* Add spacing between paperclip and input */
  margin-left: 5px;
  margin-bottom: 5px;
}

.paperclip:hover,
.paperclip:focus {
  background-color: transparent;
}

.paperclip img {
  height: 30px;
  width: auto;
}

.attached-indicator {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.attached-indicator img {
  height: 30px;
  width: auto;
}