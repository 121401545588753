.tiers-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 100px;
}

.tiers-content {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.tier-card {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
  width: 500px;
  height: 600px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left; /* Changed from center to left */
  border: 1px solid black; /* Add thin black border */
  transition: all 0.3s ease; /* Add smooth transition for hover effects */
}

.tier-card:hover {
  background-color: #f0f0f0; /* Light gray background on hover */
  transform: scale(1.05); /* Slightly enlarge the card on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Enhance shadow on hover */
}

.tier-card h2 {
  margin-bottom: 10px;
  color: #333;
  text-align: center; /* Center the title */
}

.tier-card p {
  margin-bottom: 10px;
  color: #666;
}

.price {
  font-size: 24px;
  font-weight: bold;
  color: #4a4a4a;
  text-align: center; /* Keep the price centered */
}

.tier-description {
  text-align: left;
}

.tier-description h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.tier-description ol {
  padding-left: 20px;
}

.tier-description li {
  margin-bottom: 10px;
}
