/* Banner Container */
.banner {
  width: 100%;
  height: 60px; /* Fixed height */
  background-color: white;
  border-bottom: 1px solid lightgrey;
  display: flex;
  align-items: center;
  padding-left: 20px; /* Adjust padding as needed */
  position: fixed; /* Keep the banner fixed at the top */
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure the banner is above other elements */
}

/* SBC Logo */
.banner-logo {
  height: 60px; /* Adjust logo size as needed */
}

/* Menu Button */
.menu-button {
  height: 25px; /* Adjust size as needed */
  width: 25px;  /* Adjust size as needed */
  margin-right: 20px; /* Space between menu button and logo */
  margin-left: 30px; /* Space between menu button and logo */
  cursor: pointer;
}

.settings-button {
  height: 35px; /* Adjust size as needed */
  width: 35px;  /* Adjust size as needed */
  cursor: pointer;
  position: absolute;
  right: 80px; /* Add some padding from right edge */
  top: 50%;
  transform: translateY(-50%);
}

/* Sidebar Styling */
.sidebar {
  position: fixed;
  bottom: 0; /* Align with top of viewport */
  left: -250px; /* Start hidden (width of sidebar) */
  width: 250px; /* Sidebar width */
  height: 90%; /* Full viewport height */
  background-color: #f4f4f4; /* Sidebar background color */
  box-shadow: 2px 0 5px rgba(0,0,0,0.3);
  transition: left 0.3s ease;
  padding: 20px;
  box-sizing: border-box;
  z-index: 999; /* Just below the banner */
}

.sidebar.open {
  left: 0; /* Slide in */
}

/* Sidebar Content */
.sidebar h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 20px;
  text-align: left; /* Ensure left alignment */
}

/* Chat List Styling */
.sidebar .chat-list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  max-height: 99%; /* Adjust as needed */
  overflow: visible;
}

/* Each Chat Item */
.sidebar .chat-list li {
  margin-bottom: 10px;
}

/* Chat Link Styling */
.sidebar .chat-link {
  background-color: #e0e0e0; /* Slightly darker grey background */
  border: 1px solid #ccc; /* Light border for definition */
  padding: 10px; /* Padding inside the box */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px;
  color: #333;
  cursor: pointer;
  text-align: left;
  width: 90%;
  transition: background-color 0.2s, color 0.2s;
}

/* Hover Effect for Chat Links */
.sidebar .chat-link:hover {
  background-color: #d5d5d5; /* Darker grey on hover */
  color: #007BFF; /* Change text color on hover */
}

/* Menu Links */
.sidebar .menu-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Chats Title */
.sidebar-header h2 {
  font-size: 20px;
  margin: 0;
  text-align: left; /* Ensure left alignment */
}

/* Home Icon */
.sidebar-header .home-icon {
  height: 24px; /* Adjust icon size as needed */
  width: 24px;
  cursor: pointer;
}

/* Each Menu Link Item */
.sidebar .menu-links li {
  margin: 15px 0;
}

/* Menu Link Styling */
.sidebar .menu-links a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
  display: block; /* Make the entire area clickable */
  transition: color 0.2s;
}

/* Hover Effect for Menu Links */
.sidebar .menu-links a:hover {
  color: #007BFF;
}

/* Overlay Styling (Optional for Better UX) */
.overlay {
  position: fixed;
  top: 0;
  left: 225px; /* Same as sidebar width */
  width: calc(100% - 225px);
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease;
  z-index: 998; /* Just below the sidebar */
}

/* Shift Main Content When Sidebar is Open */
body.sidebar-open .main-content {
  transform: translateX(225px);
  transition: transform 0.3s ease;
}

/* Ensure smooth transition for main content */
.main-content {
  transition: transform 0.3s ease;
  margin-top: 60px; /* Prevent overlap with fixed banner */
}

/* Responsive Design */
@media (max-width: 768px) {
  .sidebar {
    width: 80%; /* Wider sidebar on smaller screens */
    left: -80%;
  }

  body.sidebar-open .main-content {
    transform: translateX(80%);
  }

  .overlay {
    left: 80%;
    width: 20%;
  }
}

/* Adjust chat-link to be a flex container */
.chat-link {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.chat-link:hover {
  background-color: #d5d5d5;
  color: #007BFF;
}

/* Style for the chat title */
.chat-title {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Adjust options button */
.chat-options-button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.chat-options-button:hover {
  background-color: transparent; /* Prevents hover background color */
}

.chat-options-button img {
  height: 10px; /* Adjust icon size as needed */
  outline: none;
}

/* Position the options menu */
.chat-list-item {
  position: relative; /* For positioning the options menu */
}

/* Options Menu */
.chat-options-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: gray;
  border: 1px solid black;
  z-index: 1;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
}

.chat-options-menu button {
  display: block;
  width: 100%;
  padding: 5px 10px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
}

.chat-options-menu button:hover {
  background-color: #a3a3a392;
}