/* Settings.css */

/* Settings Page Container */
.settings-page {
    padding: 60px 20px 20px 20px; /* Top padding accounts for fixed header */
    background-color: #f9f9f9;
    min-height: 100vh;
    box-sizing: border-box;
  }
  
  /* Header Styling */
  .settings-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: white;
    border-bottom: 1px solid lightgrey;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 1001; /* Above sidebar */
  }
  
  /* Back Button Styling */
  .back-button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-right: 20px;
    position: absolute;
  }
  
  .back-button img {
    height: 24px; /* Adjust icon size as needed */
  }

  .back-button:hover {
    background-color: transparent;
  }
  
  /* Settings Title Styling */
  .settings-title {
    font-size: 20px;
    margin: 0;
    flex-grow: 1;
    text-align: center;
  }
  
  /* Options Container */
  .settings-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
  }
  
  /* Individual Option Button */
  .settings-option {
    background-color: #f0f0f0;
    border: none;
    border-radius: 8px;
    padding: 12px 30px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.2s, color 0.2s;
    width: auto;
    min-width: 120px;
    max-width: 200px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .settings-option:hover {
    background-color: #e5e5e5;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .settings-header {
      padding: 0 10px;
    }
  
    .settings-title {
      font-size: 18px;
    }
  
    .settings-option {
      padding: 12px;
      font-size: 14px;
    }
  }
  