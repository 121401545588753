.button-grid-container {
  width: 100%;
  transition: margin-left 0.3s ease, width 0.3s ease; /* Add transitions */
}

/* Adjust layout when sidebar is open */
body.sidebar-open .button-grid-container {
  margin-left: 245px; /* Shift content to the right */
  width: calc(100% - 245px); /* Reduce the width */
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 80px;
  gap: 10px;
  padding: 20px;
}

.grid-button {
  position: relative; /* For positioning the overlay */
  border-radius: 50px;
  background: black;
  border: 2px solid black;
  padding: 0;
  cursor: pointer;
  overflow: hidden; /* Ensures the overlay stays within the button's bounds */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

.grid-button img {
  width: 100%;
  height: auto;
  border-radius: 50px;
}

/* Hover effect with a white overlay */
.grid-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0); /* Initially transparent */
  transition: background-color 0.3s ease; /* Smooth transition for the overlay */
  border-radius: 50px;
}

.grid-button:hover::before {
  background-color: rgba(255, 255, 255, 0.2); /* White semi-transparent overlay */
}

.grid-button:hover {
  transform: scale(1.05); /* Slightly increase size */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Add a smooth, subtle shadow */
}
