.checkout-container {
  display: flex;
  width: 85%;
  height: 70%;
  margin: 120px auto;
  padding: 20px;
  gap: 60px;
}

/* Receipt Section */
.receipt-section {
  flex: 1;
  background: #f8f9fa;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.receipt-details {
  margin-top: 20px;
}

.receipt-item {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.tier-badge {
  background: #007bff;
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 0.9em;
}

.schools-list {
  margin: 20px 0;
}

.schools-list ul {
  list-style: none;
  padding: 0;
}

.schools-list li {
  padding: 8px 0;
  border-bottom: 1px solid #e0e0e0;
  color: #555;
}

.receipt-total {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 2px solid #007bff;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1.2em;
}

/* Payment Section */
.payment-section {
  flex: 1;
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.payment-form {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  gap: 40px;
}

.form-row .form-group {
  flex: 1;
}

label {
  display: block;
  margin-bottom: 8px;
  color: #555;
  font-weight: 500;
  text-align: left;
}

input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  background: #f8f9fa;
}

input:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.checkout-button {
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  font-size: 18px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.checkout-button:hover {
  background-color: #0056b3;
}

h2 {
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
  text-align: left;
}

.price {
  color: #007bff;
  font-weight: bold;
}